module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PortFolio de Hugo Brancatelli","short_name":"Portfolio","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"static/images/logo192.png","icons":[{"src":"/logo192.png","sizes":"192x192","type":"image/png"},{"src":"/logo512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"04a6d547345ac113a521b5b252b374f4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
